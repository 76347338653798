<template>

  <n-table :single-line="false" style="max-width: 40em">
    <thead>
      <tr>
        <th>Kriterijus</th>
        <th>Svoris</th>
        <th>Tiekėjas 1</th>
        <th>Tiekėjas 2</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Kaina</td>
        <td style="min-width: 10em">
          {{weight_price1}} <n-slider v-model:value="weight_price1" :step="1" />
        </td>
        <td style="min-width: 7em">
          <n-input-number v-model:value="price1" placeholder="Kaina" :min="min_price" :max="max_price">
            <template #prefix>€</template>
          </n-input-number>
        </td>
        <td style="min-width: 7em">
          <n-input-number v-model:value="price2" placeholder="Kaina" :min="min_price" :max="max_price">
            <template #prefix>€</template>
          </n-input-number>
        </td>
      </tr>
      <tr>
        <td>Atvežimas</td>
        <td>{{weight_price2}}<n-slider :value="weight_price2" :step="1" disabled /></td>
        <td>❌</td>
        <td>✅</td>
      </tr>
      <tr>
        <td style='border:none;'></td>
        <td style='border:none;'></td>
        <td style="color: rgb(118, 124, 130)"><b>Balai:</b><br>kaina {{score_price1.toFixed(1).replace('.', ',')}}<br> atvežimas {{score_trans1.toFixed(1).replace('.', ',')}}<br><u> iš viso {{(score_price1 + score_trans1).toFixed(1).replace('.', ',')}}</u></td>
        <td style="color: rgb(118, 124, 130)"><b>Balai:</b><br>kaina {{score_price2.toFixed(1).replace('.', ',')}}<br> atvežimas {{score_trans2.toFixed(1).replace('.', ',')}}<br><u> iš viso {{(score_price2 + score_trans2).toFixed(1).replace('.', ',')}}</u></td>
      </tr>
    </tbody>
  </n-table>

  <br>

  <n-collapse>
    <template #arrow>
      <n-icon>
        <settings-outline />
      </n-icon>
    </template>
    <n-collapse-item title="Kiti skaičiavimų parametrai" name="1">

      <n-input-group>
        <n-input-group-label>Maksimali kaina</n-input-group-label>
        <n-input-number v-model:value="max_price" placeholder="Max Kaina" :min="Math.max(price1, price2)" :max="10000">
          <template #prefix>€</template>
        </n-input-number>
      </n-input-group>


    </n-collapse-item>
  </n-collapse>

  <br> <br>

  <div>
    <n-space horizontal>
      <n-statistic>
        <template #label>
          Tiekėjo 1 pasiūlymo kaina, kuriai esant <br>abu tiekėjai surinktų vienodą balų kiekį
        </template>
        <template #prefix>
          <n-icon>
            <cash-outline />
          </n-icon>
        </template>
        <template #suffix>
          {{(price1_).toFixed(1).replace('.', ',')}} €
        </template>
      </n-statistic>
      <br><br>
      <n-statistic>
        <template #label>
          Atvežimo vertė <br> &nbsp;
        </template>
        <template #prefix>
          <n-icon>
            <car-outline />
          </n-icon>
        </template>
        <template #suffix>
          {{(price2 - price1_).toFixed(1).replace('.', ',')}} €
        </template>
      </n-statistic>

    </n-space>
  </div>

</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { CarOutline, CashOutline, SettingsOutline  } from '@vicons/ionicons5'


export default defineComponent({
  components: {
    CarOutline,
    CashOutline,
    SettingsOutline,
  },
  setup () {
    const max_price = ref(1000)
    const min_price = 0
    const price1 = ref(800)
    const price2 = ref(900)
    const weight_price1 = ref(90)
    const weight_price2 = computed(() => ( 100 - weight_price1.value ))

    const score_price1 = computed(() => (weight_price1.value * ((max_price.value - price1.value) / (max_price.value - min_price))))
    const score_price2 = computed(() => (weight_price1.value * ((max_price.value - price2.value) / (max_price.value - min_price))))

    const score_trans1 = computed(() => (weight_price2.value * 0))
    const score_trans2 = computed(() => (weight_price2.value * 1))

    const price1_ = computed(() => (
      price2.value
      - ((weight_price2.value * 1 * max_price.value) / weight_price1.value)
      + ((weight_price2.value * 1 * 0) / weight_price1.value)
    ))

    return {
      max_price,
      min_price,
      price1,
      price2,
      weight_price1,
      weight_price2,
      score_price1,
      score_price2,
      score_trans1,
      score_trans2,

      price1_,
    }
  }
})
</script>

<style scoped>
thead {
  /* background-color: #dee2e01e; */
}

table, td, th {
  /* border-spacing: 10px; */
  border-collapse: collapse;
  border: 1px solid var(--n-border-color);
  /* padding: 1em; */
}

.scaled {
  transform: scale(1.5);
}

</style>
